.pdf-header-wrapper {
	padding: 5px;
	padding-left: 20px;
	display: grid;
	grid-template-rows: min-content min-content;
	grid-gap: 10px;
}
.pdf-header-title {
	font-size: 1.6em;
	font-weight: 700;
	letter-spacing: 1.2px;
}
.pdf-header-subtitle {
	font-size: 1.4em;
	font-weight: 600;
	letter-spacing: 0.8px;
}
@media screen and (max-width: 600px) {
	.pdf-header-title {
		font-size: 1.2em;
	}
	.pdf-header-subtitle {
		font-size: 1em;
	}
}