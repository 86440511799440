.carousel {
	display: flex;
	overflow: hidden;
	height: calc(100% - 20px);
}
.inner {
	white-space: nowrap;
	transition: transform 0.5s;
	width: 100%;
}
.carousel-item {
	display: inline-flex;
	height: calc(100% - 5px);
}
.mobile-arrow-right {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-left: 10px solid rgba(126, 126, 132, 1);
  cursor: pointer;
}
.mobile-arrow-left {
  width: 0; 
  height: 0; 
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  border-right: 10px solid rgba(126, 126, 132, 1);
  cursor: pointer;
}
.mobile-arrow-right:hover {
  border-left: 10px solid rgba(255, 255, 255, 1);
}
.mobile-arrow-left:hover {
  border-right: 10px solid rgba(255, 255, 255, 1);
}