@import 'variables';

body {
  margin: 0;
  overflow: hidden;
  font-family: $font-family;
  background-color: $dark-bg;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color:transparent;
}
.App {
  overflow: hidden;
  display: grid;
}