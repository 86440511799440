.chat-interface {
  width: 250px;
  margin: 0;
  border: 1px solid rgba(189, 195, 199, 1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  display: flex;
  flex-direction: column;  
}
.chat-header {
  background-color: rgba(52, 152, 219, 1);
  color: rgba(255, 255, 255, 1);
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
}
.mapboxgl-popup-content {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}
.mapboxgl-popup-tip {
  display: none !important;
}


