.pdf-unit-price {
	display: flex;
	justify-content: space-around;
	text-align: center;
}
.pdf-unit-price-value {
	color: rgba(255, 255, 255, 1);
	padding: 5px;
	padding-right: 10px;
	padding-left: 10px;
	border-radius: 5px;
}