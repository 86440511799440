@import 'variables';

.calculator-wrapper {
  background-color: $components-bg;
  border-radius: 20px;
}
.calculator-item-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  color: $light-color;
  font-size: 0.8em;
}
.calculator-item {
  padding: 3px;
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 3px;
  text-align: center;
}
.calculator-text {
  color: $light-semitransparent;
  padding-top: 5px;
}