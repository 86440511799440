.property-type-header {
	display: grid; 
	align-items: center; 
	grid-template-columns: auto min-content min-content; 
	padding-right: 20px;
}
.refresh {
	padding-top: 2px; 
	cursor: pointer;
	width: 17px;
}