.property-parameters {
	display: grid;
	width: 100%;
}
.property-parameter {
	align-items: center;
	border-bottom: 1px solid rgba(0, 0, 0, 0.4);
	padding-right: 20px;
	padding-left: 20px;
	white-space: nowrap;
	display:flex;
	justify-content: space-between;
}
.property-parameter:first-child {
	border-top: 1px solid rgba(0, 0, 0, 0.4);
}
.property-parameter:last-child {
	display: flex;
	justify-content: space-between;
	padding-right: 0;
}
.property-dsv {
	width: 100%;
	padding-right: 20px;
	display: flex;
	justify-content: space-between;
}