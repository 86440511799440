@import 'variables';

.bottom-item-wrapper {
	display: grid;
	grid-template-rows: 30px auto;
	background-color: $components-bg;
	border-radius: 20px;
	overflow: hidden;
	color: $light-color;
	width: 100%;
}
.inner-wrapper {
	 display: grid;
	 grid-template-columns: min-content auto min-content;
	 grid-gap: 10px;
	 height: 100%;
	 align-items: center;
}
.bottom-prices-item-wrapper {
	display: grid;
	grid-template-rows: 30px auto min-content;
	background-color: $components-bg;
	border-radius: 20px;
	overflow: hidden;
	color: $light-color;
	width: 100%;
}