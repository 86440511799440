$font-size-normal: 1.2em;
$base-color: rgba(23, 23, 34, 1);
$switch-price-height: 17px;
$active-background-color: rgba(33, 33, 43, 1);

.switch-price {
  width: 80px;
  margin: auto;
  height: $switch-price-height;
  position: relative;
  display: grid;
  background-color: $base-color;
  border: 2px solid rgba(126, 126, 132, 1);
  border-radius: 5px;
  font-size: 0.6em;
  cursor: pointer;
}
/* Hide default HTML checkbox */
.switch-price input {
  opacity: 0;
  width: 0;
  height: 0;
}
/* The slider-price */
.slider-price {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  -webkit-transition: .4s;
  transition: .4s;
}
.slider-price:before {
  position: absolute;
  content: "";
  height: $switch-price-height;
  width: calc(50% + 5px);
  left: -2px;
  top: -1px;
  background-color: $active-background-color;
  -webkit-transition: .4s;
  transition: .4s;
  border: 1px solid rgba(255, 255, 255, 1);
}
input:checked + .slider-price {
  background-color: rgba(23, 23, 34, 1);
}
input:checked + .slider-price:before {
  -webkit-transform: translateX(calc(100% - 10px));
  -ms-transform: translateX(calc(100% - 10px));
  transform: translateX(calc(100% - 10px));
}
/* Rounded sliders */
.slider-price.round {
  border-radius: 5px;
}
.slider-price.round:before {
  border-radius: 5px;
}
.switch-price .labels {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    top: 2px;
    font-weight: 500;
    font-size: 1.2em;
}
.switch-price .labels::after {
    content: attr(data-off);
    position: absolute;
    right: 7px;
    color: rgba(126, 126, 132, 1);
    transition: all 0.4s ease-in-out;
}
.switch-price .labels::before {
    position: absolute;
    content: attr(data-on);
    left: 15px;
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
    transition: all 0.4s ease-in-out;
}
.switch-price input:checked~.labels::after {
    color: rgba(255, 255, 255, 1);
    font-weight: 500;
}
.switch-price input:checked~.labels::before {
    color: rgba(126, 126, 132, 1);
}