@import 'variables';

.sidebar-search {
  display: grid;
  height: 100%;
  grid-template-columns: min-content auto min-content;
  position: relative;
  border-radius: 5px;
  align-items: center;
  padding: 3px;
  background-color: black;
}
.maps-input {
  color: $light-color;
  border: none;
  font-size: 1em;
}
.maps-input:focus {
  outline: none;
}
.cancel-cross-wrapper {
  width: 30px;
  display: grid;
}
.cancel-cross {
  width: 15px;
  cursor: pointer;
  align-self: center;
  justify-self: center;
}
.header-search-icon {
  cursor: pointer;
  height: 15px;
  align-self: center;
  padding-left: 17px;
}