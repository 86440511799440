.tooltip-wrapper {
  z-index: 3;
  pointer-events: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 1);
  cursor: pointer;
  border-radius: 5px; 
  border: 2px solid rgba(126, 126, 132, 0.6);
  display: grid;
  grid-template-rows: 30px auto;
  padding: 10px;
  padding-top: 0;
  line-height: 30px;
}
.tooltip-header {
  color: rgba(255, 255, 255, 1); 
  font-size: 1.2em;
  display: grid;
  grid-template-columns: auto min-content;
  white-space: nowrap;
  align-items: center;
}