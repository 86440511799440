.input-group {
	display: flex;
	width: 100%;
	justify-content: space-around;
	align-items: center;
}
.input-group input {
    width: 40px;
    text-align: center;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 3px;
}
.input-group input::-webkit-inner-spin-button,
.input-group input::-webkit-outer-spin-button {
    opacity: 1;
}