.right-selector-wrapper {
	cursor: pointer;
	display: grid;
	grid-gap: 10px;
	grid-template-columns: repeat(4, 1fr);
	padding-right: 5px;
	img {
		height: 15px;
		max-width: 20px;
	}
}