.dropdown-wrapper {
  white-space: nowrap;
  position: relative;
  width: 60px;
  height: 20px;
  cursor: pointer;
  background-color: rgba(23, 23, 34, 1);
  border-radius: 5px;
  border: 2px solid rgba(126, 126, 132, 1);
  color: rgba(255, 255, 255, 1);
  z-index: 6;
  font-size: 0.8em;
}
.dropdown-header {
  height: 20px;
  display: grid;
  grid-template-columns: auto 15px;
  align-items: center;
  text-align: center;
  img {
    margin: auto;
  }
}
.suggestions {
  top: 22px;
  text-align: center;
  position: absolute;
  list-style-type: none;
  padding: 0;
  left: 0;
  margin: 0;
  width: 100%;
  background-color: rgba(23, 23, 34, 1);
  color: rgba(126, 126, 132, 1);
  border-radius: 0px 0px 5px 5px;
  li {
    padding: 5px;
  }
  li:hover {
    border-radius: 0px 0px 5px 5px;
    background-color: rgba(43, 20, 120, 1);
    color: rgba(255, 255, 255, 1);
  }
}