.chat-input-container {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: white;
  border-top: 1px solid rgba(189, 195, 199, 1);
}
.chat-input {
  flex: 1;
  height: 40px;
  border: 1px solid rgba(189, 195, 199, 1);
  border-radius: 4px;
  padding: 8px;
  resize: none;
  font-size: 16px;
  color: rgba(52, 73, 94, 1);
}
.chat-input:focus {
  outline: 1px solid rgba(52, 73, 94, 1);
}
.chat-send-button {
  margin-left: 10px;
  padding: 8px 12px;
  background-color: rgba(52, 152, 219, 1);;
  color: rgba(255, 255, 255, 1);
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.chat-send-button:hover {
  background-color: #0056b3;
}
.chat-input::-webkit-scrollbar {
  width: 0;
}