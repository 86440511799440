@import 'variables';

.right {
	overflow: hidden;
}
.right-wrapper {
	display: grid; 
	height: 100%;
	grid-template-rows: min-content 1fr 1fr 1fr;
	grid-gap: 5px;
  	color: $light-color;
}
.right-item-wrapper {
	display: grid;
	grid-template-rows: 30px auto min-content;
	background-color: $components-bg;
	border-radius: 20px;
	overflow: hidden;
	color: $light-color;
}