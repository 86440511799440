.pdf-areas-input {
	width: 20px;
	border: 1.5px solid rgba(126, 126, 132, 0.4);
	border-radius: 2px;
	text-align: center;
	font-size: 0.8em;
}
.areas-title-item {
	display: flex;
	gap: 5px;
}