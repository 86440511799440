.pdf-graphics-wrapper {
  display: grid; 
  grid-template-columns: 1fr 1fr; 
  overflow: hidden;
  grid-gap: 20px;
  padding-right: 20px;
  padding-left: 20px;
}
.pdf-graphics-item-wrapper {
  display: grid;
  grid-template-rows: 30px auto;
  align-items: center;
}