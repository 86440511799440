@import 'variables';

.location-wrapper {
  display: flex;
  gap: 10px;
  background: $components-bg;
  border-radius: 5px;
  color: $light-color;
  height: 40px;
  align-items: center;
  padding-left: 5px;
  overflow: hidden;
  font-size: 0.8em;
  padding-left: 20px;
}