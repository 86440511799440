.boundary-controller {
  width: 20px;
  height: 20px; 
  background-color: rgba(233, 233, 233, 1); 
  border: 0.1px solid rgba(32, 32, 43, 1);
  border-radius: 3px;
  padding: 5px;
  z-index: 3;
}
.graphic-tools {
  position: absolute; 
  top: 10px; 
  left: 20px; 
  display: grid; 
  gridGap: 5px;
  gridTemplateColumns: min-content min-content min-content 100px 100px 100px;
}