.initial-marker-text {
  position: absolute;
  left: 50%;
  top: calc(50% + 20px);
  transform: translate(-50%, 0);
  color: rgba(255, 255, 255, 1);
  white-space: nowrap;
  z-index: 3;
  background-color: rgba(33, 33, 43, 0.6);
  padding: 20px;
  border-radius: 20px;
}
.mapboxgl-marker {
  z-index: 3;
}