.pdf-prices {
	display: grid;
	padding: 10px;
	padding-left: 20px;
	padding-right: 20px;
	grid-gap: 10px;
}
.pdf-prices-wrapper {
	display: flex;
	justify-content: space-between;
	font-size: 1.4em;
}