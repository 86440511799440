.pdf-footer {
	display: flex;
	justify-content: space-between;
	padding-right: 20px;
	padding-left: 20px;
	border-top: 1px solid rgba(0, 0, 0, 1);
}
.data-provider-logo {
	display: grid;
	grid-template-columns: auto min-content;
	align-items: center;
	grid-gap: 5px;
}
.m2b-letters {
	 font-style: italic; 
	 font-size: 1.4em;
}
.imoveis-sc-logo {
	height: 10px;
	padding-top: 2px;
}