.pictures-footer {
  background-color: rgba(33, 33, 43, 1);
  display: grid;
  align-items: center;
  grid-auto-flow: column;
  grid-gap: 15px;
  justify-content: center;
  font-size: 0.8em;
}
.pictures-footer-item {
  display: grid;
  grid-auto-flow: column;
  align-items: center;
  grid-gap: 2px;
}