.chat-history {
  flex: 1;
  padding: 10px;
  background-color: rgba(255, 255, 255, 1);
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 200px;
}
.chat-message {
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 4px;
  max-width: 90%;
  font-size: 14px;
}
.chat-message.assistant {
  background-color: rgba(223, 246, 255, 1);
  align-self: flex-start;
}
.chat-message.user {
  background-color: #d1ffd1;
  align-self: flex-end;
  text-align: right;
}