.rooms-wrapper {
	display: grid;
	grid-template-columns: min-content auto;
	width: 100%;
	height: 100%;
	padding-left: 20px;
}
.rooms-legend-item-wrapper {
	display: grid;
	grid-template-columns: min-content min-content min-content;
	grid-gap: 10px;
}
.rooms-legend-wrapper {
	align-self: center;
	display: grid;
	grid-gap: 10px;
}
.rooms-legend-item {
	height: 20px;
	width: 20px;
	cursor: pointer;
}
.rooms-legend-text {
	white-space: nowrap;
	cursor: pointer;
	font-size: 0.8em;
	color: rgba(126, 126, 132, 1);
}
.rooms-legend-text.active {
	color: rgba(255, 255, 255, 1);
}