$background-color: rgba(0, 0, 0, 1);
$color: rgba(255, 255, 255, 1);

.ads-pictures-wrapper {
  display: grid;
  background-color: $background-color;
  grid-template-rows: 30px auto 30px;
  grid-gap: 10px;
  position: relative;
  overflow: hidden;
  border-radius: 20px;
  z-index: 7;
}
.pictures-wrapper {
  position: absolute;
  width: 100%;
  top: 100px;
  display: grid;
  grid-template-columns: auto 50% auto;
  color: $color;
}
@media screen and (max-width: 1000px) {
  .pictures-wrapper {
    top: 0px;
    grid-template-columns: auto;
  }
  .ads-pictures-wrapper {
    align-self: start;
    height: calc(100vh - 180px - 45px);
    border-radius: 0;
  }
}