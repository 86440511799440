.rooms-wrapper {
	width: 100%;
	height: 100%;
	display: grid;
	grid-template-columns: 1fr 2fr;
}
.property-type-header {
	display: grid; 
	align-items: center; 
	grid-template-columns: auto min-content min-content; 
	padding-right: 20px;
}