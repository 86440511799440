.m2b-header{
  background-color: rgba(0, 0, 0, 1);
  display: grid;
  grid-template-columns: min-content auto auto min-content min-content;
  grid-gap: 8px;
  align-items: center;
  padding-right: 20px;
  padding-left: 20px;
}
.small-header {
  display: none;
}
@media screen and (max-width: 600px) {
  .small-header {
    display: block;
  }
  .normal-header {
    display: none;
  }
}