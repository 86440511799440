.samples-header {
  display: grid;
  grid-template-columns: min-content auto min-content;
  white-space: nowrap;
  align-items: center;
  padding-right: 30px;
  gap: 10px;
}
.samples-item-wrapper {
	border-radius: 5px;
	display: flex;
	background-color: rgba(23, 23, 32, 1);
	border: 2px solid rgba(126, 126, 132, 1);
}
.samples-plus-sign {
	color: rgba(0, 146, 69, 1);
	width: 100%;
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
	border-left: 2px solid rgba(126, 126, 132, 1);
	cursor: pointer;
}
.samples-minus-sign {
	color: rgba(255, 0, 0, 1);
	width: 100%;
	text-align: center;
	padding-left: 5px;
	padding-right: 5px;
	border-right: 2px solid rgba(126, 126, 132, 1);
	cursor: pointer;
}
.samples-number {
	width: 20px;
	background-color: rgba(23, 23, 32, 1);
	border: none;
	font-weight: 580;
	text-align: center;
	color: rgba(255, 255, 255, 1);
}
.samples-number:focus {
  outline: none;
}
.samples-active-url {
	cursor: pointer;
}
.samples-add-url {
	position: relative;
	display: grid;
	align-items: center;
}