.fixTableHead { 
  overflow-y: scroll; 
  table {
    width: 100%;
    border-collapse: collapse;
  }
  thead{ 
    position: sticky; 
    top: 0; 
    background-color: rgba(241, 241, 241, 1);

  } 
  tr:hover {
    background: rgba(126, 126, 132, 0.1);
  }
  th { 
    border-top: none;
    border-bottom: none;
    box-shadow: inset 0 1px 0 rgba(0, 0, 0, 0.4), inset 0 -1px 0 rgba(0, 0, 0, 0.4);
    padding: 8px;
  } 
  td {
      padding: 8px;
      border: 1px solid rgba(0, 0, 0, 0.2);
      border-top: none;
  }
} 