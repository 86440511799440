.pdf-dropdown-wrapper {
  white-space: nowrap;
  position: relative;
  cursor: pointer;
  color: rgba(0, 0, 0, 1);
}
.pdf-dropdown-header {
  display: grid;
  grid-gap: 3px;
  grid-template-columns: auto 10px;
  align-items: center;
  text-align: center;
  height: 100%;
}
.pdf-suggestions {
  top: 100%;
  width: 100%;
  text-align: center;
  position: absolute;
  list-style-type: none;
  padding: 0;
  left: 0;
  margin: 0;
  background-color: rgba(255, 255, 255, 1);
  border-radius: 0px 0px 5px 5px;
  z-index: 2;
  li {
    padding: 5px;
  }
  li:hover {
    background-color: rgba(126, 126, 132, 1);
    color: rgba(255, 255, 255, 1);
  }
}